<template>
  <div v-if="clientsData" class="">
    <div class="table-container">
      <table class="my-table">
        <thead>
          <tr>
            <th>#</th>
            <th>{{ $t("code") }}</th>
            <th>{{ $t("name") }}</th>
            <th>{{ $t("phoneNumber") }}</th>
            <th>{{ $t("email") }}</th>
            <th>{{ $t("info") }}</th>
            <th>{{ $t("edit") }}</th>
            <th>{{ $t("servicesReservations") }}</th>
            <th>{{ $t("historicalPlans.modelName") }}</th>
            <th>{{ $t("servicesPayments") }}</th>
            <th>{{ $t("reservationsMedias.modelName") }}</th>
            <th>{{ $t("QRcode") }}</th>
            <th>{{ $t("delete") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(clientData, index) in clientsData"
            :key="clientData.clientToken"
          >
            <td>{{ ++index }}</td>
            <td>{{ isDataExist(clientData.clientCode) }}</td>
            <td>
              <img
                class="item-img-table"
                :src="`${baseUrl}${clientData.clientImagePath}`"
                :onerror="`this.src='${defaultImg}'`"
                alt="Image"
              />
              {{ isDataExist(clientData.clientNameCurrent) }}
            </td>
            <td>{{ isDataExist(clientData.clientPhoneWithCC) }}</td>
            <td>{{ isDataExist(clientData.clientEmail) }}</td>
            <td>
              <button
                v-b-modal.ClientInfo
                class="btn p-0"
                :title="$t('info')"
                @click="setClientData(clientData)"
              >
                <img src="@/assets/images/info.svg" class="icon-lg" />
              </button>
            </td>
            <td>
              <router-link
                :to="'/edit-client/' + clientData.clientToken"
                :title="$t('edit')"
              >
                <img src="@/assets/images/pencil.svg" class="icon-lg" />
              </router-link>
            </td>
            <td>
              <button
                class="btn p-0"
                :title="$t('servicesReservations')"
                @click="goToServicesReservations(clientData.clientToken)"
              >
                <img
                  src="@/assets/images/services-reservations.svg"
                  class="icon-lg"
                />
              </button>
            </td>
            <td>
              <button
                class="btn p-0"
                :title="$t('historicalPlans.modelName')"
                @click="goToHistoricalPlan(clientData.clientToken)"
              >
                <img
                  src="@/assets/images/historicalPlans.svg"
                  class="icon-lg"
                />
              </button>
            </td>
            <td>
              <button
                class="btn p-0"
                :title="$t('servicesPayments')"
                @click="goToPayments(clientData.clientToken)"
              >
                <img
                  src="@/assets/images/services-payments.svg"
                  class="icon-lg"
                />
              </button>
            </td>
            <td>
              <router-link
                :to="{
                  name: 'ReservationsMedias',
                  params: {
                    clientToken: clientData.clientToken,
                  },
                }"
                :title="$t('reservationsMedias.modelName')"
              >
                <img src="@/assets/images/media.svg" class="icon-lg" />
              </router-link>
            </td>
            <td>
              <button
                v-b-modal.ClientQRCode
                class="btn p-0"
                :title="$t('QRcode')"
                @click="setClientData(clientData.clientCode)"
              >
                <qrcode-vue
                  class="icon-lg"
                  :value="clientData.clientCode"
                  :size="40"
                  level="H"
                />
              </button>
            </td>
            <td>
              <button
                v-b-modal.ClientDelete
                class="btn p-0"
                :title="$t('delete')"
                @click="setClientData(clientData.clientCode)"
              >
                <img src="@/assets/images/trash.svg" class="icon-lg" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { BASE_URL } from "@/utils/constants";
import DEFAULT_IMG_CLIENT from "@/assets/images/clients.svg";
import QrcodeVue from "qrcode.vue";
import { REQUIREMENTS_TYPE_TOKENS } from "@/utils/constantLists";
import { setDataMultiLang, timeToLang, isDataExist } from "@/utils/functions";

export default {
  name: "ClientsTable",
  components: {
    QrcodeVue,
  },
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
      defaultImg: DEFAULT_IMG_CLIENT,
      requirementsTypeTokens: REQUIREMENTS_TYPE_TOKENS,
      baseUrl: BASE_URL,
    };
  },
  props: ["clientsData"],
  methods: {
    setClientData(clientData) {
      this.$emit("setClientData", clientData);
    },
    goToFamily(clientToken) {
      this.$store.dispatch("updateClientToken", clientToken);
      this.$router.push("/client-family").catch(() => {});
    },
    goToServicesReservations(clientToken) {
      this.$store.dispatch("updateReservationsClientToken", clientToken);
      this.$router.push({ name: "ServicesReservations" }).catch(() => {});
    },
    goToPayments(clientToken) {
      this.$store.dispatch("updatePaymentsClientToken", clientToken);
      this.$store.dispatch("updatePaymentsReservationToken", "");
      this.$router.push({ name: "ServicesPayments" }).catch(() => {});
    },
    goToHistoricalPlan(clientToken) {
      this.$store.dispatch("updateHistoricalPlansClientToken", clientToken);
      this.$store.dispatch("updateHistoricalPlansReservationToken", "");
      this.$router.push({ name: "DiagnosisPlans" }).catch(() => {});
    },
    timeToLang(time) {
      return timeToLang(time, this.language);
    },
    setDataMultiLang(lang, dataAr, dataEn, dataUnd) {
      return setDataMultiLang(lang, dataAr, dataEn, dataUnd);
    },
    isDataExist(data) {
      return isDataExist(data);
    },
  },
  computed: {
    imgSrc: function () {
      return BASE_URL + this.clientData.clientImagePath;
    },
  },
};
</script>

<style lang="scss"></style>
